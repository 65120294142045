@import url("~antd/dist/antd.css");
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5 'Microsoft YaHei', Serif, \5b8b\4f53;
  font-family: 'Microsoft YaHei'
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand { display: none; }  
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
ul,
ol {
  list-style: none;
}

li{
  list-style-type:none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@mixin wid_hei($wid:100%, $hei:100%){
  width: $wid;
  height: $hei;
}

.wh100 {
  @include wid_hei(100vw, 100vh);
}

.fl{
  float: left;
}
.fr{
  float: right;
}

.container {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
}

.container:after {
  height: 0px;
}

.dispF{
  display: flex;
}
.jcsb{
  justify-content: space-between;
}
.jcc{
  justify-content: center;
}
.aic{
  align-items: center;
}

.login-error {    
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
  color:red;
}
.echarts-for-react{
  height: 100%;
}

.yahei {
  font-family: "microsoft yahei";
}

ul li{
  list-style: none !important;
}

.OFH{
  overflow: hidden;
}

.tac{
  text-align: center;
}

.ml5{
  margin-left: 5px
}
.ml10{
  margin-left: 10px
}
.ml15{
  margin-left: 15px
}

.mr15{
  margin-right: 15px
}

.ml5{
  margin-left: 5px
}
.mr1vw{
  margin-right: 1vw
}
.ml1vw{
  margin-left: 1vw
}


.mr5{
  margin-right: 5px
}

.mt15{
  margin-top: 15px
}

.mb15{
  margin-bottom: 15px
}

/****** 字体包引入 start ******/
@font-face {
  font-family: "pingfang";
  src: url(./assets/fonts/PingFang-SC-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "04b_03b";
  src: url(./assets/fonts/04B_03B_.ttf) format("truetype");
}

@font-face {
  font-family: "SourceHanSansCN";
  src: url(./assets/fonts/SourceHanSansCNRegular.ttf) format("truetype");
}

.SourceHanSansCN{
  font-family: 'SourceHanSansCN' !important;
}
/****** 字体包引入 end ******/

/* 滚动条样式 start */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px hsla(0,0%,100%,.3);
  -webkit-box-shadow: inset 0 0 6px hsla(0,0%,100%,.3);
  background-color: #d5d6d2;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px hsla(0,0%,88%,.3);
  -webkit-box-shadow: inset 0 0 6px hsla(0,0%,88%,.3);
  background-color: #bbb;
}
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #bebebe;
}
::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}
::-webkit-scrollbar {
  width: 10px;
  height: 4px;
  background-color: #bebebe;
}
/* 滚动条样式 start */


/* ant 下拉菜單樣式 start */
.ant-dropdown,
.ant-dropdown-menu-submenu{
  ul{
    border: 1px solid #65ffff;
    box-sizing: border-box;
    box-shadow: 0 0 5px #a1eaea;
    background-color: rgba(1,35,62,.9);
    margin-top: 5px;
    li{
      font-size: 14px;
      color: #fff;
      /* padding: 10px 20px; */
      text-align: left
    }
  }
}

.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-title i{
  color: #fff !important;
}

.ant-dropdown-menu-item:hover, 
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
}

/* ant 下拉菜單樣式 end */


/* 主框架样式 start */
.MainContainer,
.MainFunContainer{
  font-family: 'SourceHanSansCN' !important;
  .polestarboard {
    min-height: 100vh;
    width: 100%;
    // height: 100vh;
    // width: 100vw;
  }
}
// 看板框架样式
.MainContainer{

  $MainBoardH: 10.8vh;
  .Head{
    height: $MainBoardH;
    background: url("./assets/images/title.png") no-repeat center;
    
    .toolBox {   
      top: 20%;
    }
    .centx {
      top: 40%;
    }
    .rightLayout{
      top: 32%;
    }
  }
  .MainContent{
    height: 100vh - $MainBoardH;
  }

  .polestarboard {
    background: url('./assets/images/background.jpg') no-repeat;
    background-size: 100% 101%;
    overflow: hidden;
  }
  .MainContent{
    height: 100vh - $MainBoardH;
  }
}
// 功能框架样式
.MainFunContainer{
  .polestarboard {
    background-color:#eceef7;
  }
  $MainBoardH: 9.26vh;
  .Head{
    height: 9.26vh;
    background: url("./assets/images/tools_title.png") no-repeat center;

    .toolBox {   
      top: 50%;
      transform: translateY(-50%)
    }
    .centx {
      top: 50%;
    }
    .rightLayout{
      top: 50%;
    }
  }
  .MainContent{
    height: 100vh - $MainBoardH;
  }
}
/* 主框架样式 end */

/* 样式改写 start */
.ant-spin-nested-loading > div > .ant-spin{
  max-height: none;
}
// .ant-modal-wrap .ant-modal{
//   // top: 50%;
//   // transform: translateY(-50%);
// }

.poi-default{
	// 下拉菜单item样式
	&.ant-select-dropdown-menu-item{
		min-height: 40px;
		display: flex;
		align-items: center;
	 }

	// 模态框样式
	&.ant-modal{
		min-width: 953px;
		font-family: 'SourceHanSansCN';
	
		.ant-modal-title{
			font-size: 2.2vh;
			font-weight: 500;
		}
		.ant-modal-body{
			font-size: 1.8vh;
	
			.ant-switch-checked {
				background-color: #61CE92;
			}
		}
	}

	// 表格样式
	.ant-table {
		.ant-table-content { // 表格样式
		  .ant-table-scroll .ant-table-header{
			 overflow: hidden !important;
			 tr > th{
				color: #fff;
				background-color: rgba(0,81,212,1);
			 }
		  }
		  .ant-table-tbody {
			 overflow-y: auto;
		
			 tr:nth-child(even) > td{
				background: rgba(245,247,251,1);
			 }
		  }
		}
	 }
}

/* 样式改写 end */