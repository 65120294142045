.Head {
	// height: 10.8vh;
	width: 100%;
	// background: url("src/assets/images/title.png") no-repeat center;
	text-align: center;
	background-size: 100% 100% !important;
	position: relative;

	.logo{
		width: 5.7vw;
		max-height: 5vh;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(-50%);
		margin-top: 3vh;
		margin-left: 5.9vw;
	}
	.logo.funBoard{
		margin-top: 3.7vh;
		margin-left: 5.3vw;
	}

	.centx {
		font-weight: bold;
		font-size: 3.4vh;
		color: white;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
	}
	.toolBox {    
		left: 2%;
		position: absolute;
	}
	.img {
		width: 2.8vh;
		height: 2.8vh;
		cursor: pointer;
	}
	.rightLayout{
		width: 18%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		left: 81vw;
		transform: translateY(-50%);
		position: absolute;

		.timebox {
			font-size: 1.4vh;
			color: white;

			>p:first-child {
				font-size: 2.5vh;
			}
		}
		.boundary{
			display: inline-block;
			height: 2.8vh;
			width:2px;
			background-color: #fff;
		}
		.logout{
			cursor: pointer;
			img{
				margin-right: 0.5vw;
			}
			>span{
				font-size: 2vh;
				color: #fff;
			}
		}
	}



}

.board-popover {
	.ant-popover-inner {
		background-color: #000;
		border: 1px solid #2249E8;
		border-radius: 13px;
		box-shadow:0px 1px 16px 2px rgba(18,61,240,0.3);
		& ::-webkit-scrollbar{
			display:none;
		}
	}
	.menu,.ant-menu-inline {
		background-color: #000 !important;
		color: #fff !important;
	}

	.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
		border: none;
	}

	> .ant-popover-content > .ant-popover-arrow {
		border-color: #000 !important;
		box-shadow: -1px -1px 1px 1px #2249E8 !important;
	}

	.ant-popover-inner-content {
		padding: 1.85vh 0.4vh 3vh 0.4vh;
	}

	.ant-menu {
		max-height: 87vh;
		min-width: 20.4vh;
		border-radius: 13px;
		padding-right: 2px;
		overflow-y: auto;
	}

	.ant-menu-item:hover,
	.ant-menu-item:active,
	.ant-menu-item-active,
	.ant-menu-item-selected,
	.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
		color: #08FFFF;
		background: linear-gradient(to right,#0039fa, #09123e);

	}

  .ant-menu-item {
	position: relative;
  }

	.ant-menu-item-active {
		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			display: block;
			content: '';
			width: 1px;
			height: 100%;
			background-color: #0039fa;
		}
	}
}

.fun-popover {
  .ant-popover-inner {
    border-radius: 13px;
	box-shadow:0px 1px 16px 2px rgba(0,104,255,0.2);
	& ::-webkit-scrollbar{
		display:none;
	}
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
		border: none;
	}

  .ant-popover-inner-content {
		padding: 1.85vh 0.4vh 3vh 0.4vh;
	}

	.ant-menu {
		max-height: 87vh;
		min-width: 20.4vh;
		border-radius: 13px;
		padding-right: 2px;
		overflow-y: auto;
		& ::-webkit-scrollbar{
			display:none;
		}
  }

  	.ant-menu-item:hover,
	.ant-menu-item:active,
	.ant-menu-item-active,
	.ant-menu-item-selected,
	.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
		color: #0051D4;
		background-color: #ECEEF7;

  }
}
